<template>
  <div class="page_tpl">

    <div class="wavesCont">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" style="isolation:isolate" preserveAspectRatio="none" viewBox="5978 129.24 1440 259.76">
        <defs>
          <clipPath id="a">
            <path d="M5978 129.24h1440V389H5978z"></path>
          </clipPath>
        </defs>
        <g fill="#FFF" clip-path="url(#a)">
          <path style="animation-delay: .2s" d="M5978 153.77c166.44 0 358.45 11.66 755.24 138.08 381.36 121.5 562.3 105.94 684.76 75.81 0-15.48-.02-54.72-.1-155.11-137.43 39.67-283.82 106.09-717.65 27.58-407.86-73.8-571.8-93.89-721.75-93.89l-.5 7.53z" fill-opacity=".1"></path>
          <path style="animation-delay: .4s" d="M5978 153.77c166.44 0 358.45 11.66 755.24 138.08 381.36 121.5 562.3 105.94 684.76 75.81l-.04-53.2-1.54.37c-122.36 30.1-294.49 72.46-680.18-34.53C6334.99 169 6181.93 151.26 5978 151.26v2.51z" fill-opacity=".1"></path>
          <path d="M7418 367.66V389H5978V153.77c166.44 0 358.45 11.66 755.24 138.08C6965.46 365.84 7123.37 389 7239 389c74.27 0 131.1-9.56 178.99-21.34q0-70.8 0 0z"></path>
        </g>
      </svg>
    </div>
    <div class="quisommesnous">


      <div class="content">
        <div class="left">
          <h1>QUI SOMMES-NOUS ?</h1>
          
          <p>
            Situés dans les bouches du Rhône, nous sommes une petite équipe aux parcours professionnels divers mais complémentaires :
            Responsable-restauration, développeur, geek passionné de nouvelles technologies, chef
            d’entreprise, spécialiste en marketing digital, psychologue…
  
          </p>
          
          <p>
            Notre ambition sincère est de mettre au profit des professionnels un outil issu d’une réflexion
            poussée sur le monde de l’e-commerce et de la restauration.
          </p>
          <p>
            Comme nombre d’entre vous, nous nous sommes sentis bouleversés par les cris de désespoir
            des restaurateurs face à cette crise sanitaire et sociétale sans précédent. Pragmatiques, nous
            sommes vite tombés d’accord sur le fait que la vente en ligne était devenue la seule alternative
            possible. La solution incontournable pour faire repartir l’activité et le CA.
          </p>
          <p>
            A deux conditions près : <b>il faut que ce soit simple d’utilisation et rentable pour le restaurateur.</b>
          </p>
  
  
          <p>
            Après une analyse approfondie du marché, le bilan est sans appel, en dehors des grosses
            métropoles, la présence sur le Web des restaurateurs locaux comme le nombre de sites
            marchands est faible. Des inquiétudes techniques ou l’angoisse de coûts excessifs expliquent
            sans nul doute, ce retard de digitalisation.
          </p>
          <p>
            Partant de ce simple constat, nous avons choisi d’œuvrer sans relâche à la mise en place d’un
            système optimisé de commandes en ligne, intuitif, efficace et à faible coût. Notre objectif serait
            d’accompagner dans la durée tous les professionnels des « métiers de bouche », souhaitant
            profiter des avantages du e-commerce.
          </p>
          <p>
            Nous sommes fiers aujourd’hui de proposer Monresto.click : une marketplace française, efficace
            et soucieuse de l’économie locale.
          </p>
        </div>
        <div class="right">
          <img src="../../assets/img/illu/team.svg" alt="">
        </div>
      </div>

    </div>

    <footer_b></footer_b>
  </div>
</template>

<script>
export default {
  name: "quisommesnous",
  data() {
    return {
      content: null
    }
  },
  computed: {
    
  },
  created() {
    
  },
  methods: {
    
  }
}
</script>

<style>
.quisommesnous { display: flex; padding: 0 36px; justify-content: center; position: relative; z-index: 2; }
.quisommesnous .content { width: 100%; font-size: 16px; line-height: 20px; margin-top: 36px; font-weight: 400; padding-bottom: 100px; }
.quisommesnous .content p { font-size: 18px; line-height: 1.6; }
.quisommesnous .edit { width: 50%; }
.quisommesnous .edit .submit { margin-bottom: 16px; margin-top: 16px; }

.quisommesnous h1 { color: #fff; margin-bottom: 38vw; margin-top: 30px; font-size: 38px; line-height: 38px; }
.quisommesnous h2 { font-size: 30px; line-height: 40px; margin-bottom: 36px; }
.quisommesnous h3 { font-size: 26px; line-height: 30px; margin-top: 46px; margin-bottom: 26px; }
.quisommesnous ol { padding-left: 16px; margin-top: 36px; }
.quisommesnous li { font-weight: bold; }

.quisommesnous .content .right { display: none }

.page_tpl .wavesCont { position: absolute; top: 69px; height: 64vw; right: 0; left: 0; background: var(--orange); transform: rotateY(180deg) }
.page_tpl .wavesCont .waves { position: absolute; bottom: -1px; left: 0px; right: 0; }

.page_tpl .waves-container.no-animation * {
  animation: none!important;
}
.page_tpl .waves path {
  animation: waveIn .7s both;
}
.page_tpl .waves path:last-child {
  animation: none;
}
@keyframes waveIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}


@media only screen and (min-width: 600px) {
  .quisommesnous .content { width: 70%; }

  /*.wavesCont { height: 40vh; }*/
  /*.quisommesnous h1 { font-size: 50px; line-height: 52px; width: 70%; margin-top: 0.9vw; margin-bottom: 16vw; }*/
  .page_tpl .wavesCont { height: 280px; width: 160%; }
  .quisommesnous h1 { margin-top: 3vw; margin-bottom: 200px; font-size: 50px; line-height: 52px; width: 70%; }
}
@media only screen and (min-width: 1400px) {
  .quisommesnous h1 { margin-bottom: 200px; }
  /*.quisommesnous p { width: 800px; }*/
  .quisommesnous .left { width: 900px; }
  .quisommesnous .left p { padding-right: 100px; line-height: 1.77; }
  .quisommesnous .content { display: flex; width: 100%; margin-left: 10%; }
  .quisommesnous .content .right { display: block; margin-top: 50px; width: calc(100% - 900px); }
  .quisommesnous .content .right img { width: 100%; }
}
</style>